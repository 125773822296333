import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type {
  EnrolmentProportion,
  ProjectExperiments,
  SessionExperiments,
  UserExperiments,
} from '@freelancer/abtest';
import { ABTest } from '@freelancer/abtest';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';

@UntilDestroy({ className: 'CompatABTestComponent' })
@Component({
  selector: 'webapp-compat-abtest',
  template: ` <ng-container></ng-container> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompatABTestComponent implements OnInit {
  constructor(private abtest: ABTest) {}

  ngOnInit(): void {
    const w = window as any;
    w.webappCompatABTest = {
      getSessionExperimentVariation: (name: keyof SessionExperiments) =>
        firstValueFrom(
          this.abtest
            .getSessionExperimentVariation(name)
            .pipe(untilDestroyed(this)),
        ),
      getUserExperimentVariation: (
        name: keyof UserExperiments,
        userId?: number,
      ) =>
        firstValueFrom(
          this.abtest
            .getUserExperimentVariation(name, userId)
            .pipe(untilDestroyed(this)),
        ),
      getProjectExperimentVariation: (
        name: keyof ProjectExperiments,
        projectId: number,
      ) =>
        firstValueFrom(
          this.abtest
            .getProjectExperimentVariation(name, projectId)
            .pipe(untilDestroyed(this)),
        ),
      shouldEnrol: (
        name: keyof ProjectExperiments,
        contextId: number | string,
        testProportion: EnrolmentProportion,
      ) => this.abtest.shouldEnrol(name, contextId, testProportion),
    };
    if (w.webappCompatABTestReadyCallback) {
      w.webappCompatABTestReadyCallback();
    }
  }
}
